import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';

import { st, classes } from './Plan.st.css';
import { Cta } from '../Cta';
import { Ribbon } from '../Ribbon';
import { PlanName } from '../PlanName';
import { Price } from '../Price';
import { Recurrence } from '../Recurrence';
import { Tagline } from '../Tagline';
import { Validity } from '../Validity';
import { FreeTrial } from '../FreeTrial';
import { Footer } from '../Footer';

interface Props {
  plan: PublicPlan;
  recurringPlansExist: boolean;
  freeTrialDaysExist: boolean;
  onClick: () => void;
  highlighted: boolean;
  anyBenefits?: boolean;
  isMobile?: boolean;
}

export const Plan: React.FC<Props> = ({
  plan,
  recurringPlansExist,
  freeTrialDaysExist,
  highlighted,
  onClick,
  anyBenefits,
  isMobile,
}) => (
  <div data-hook="plan" className={st(classes.plan, { highlighted })}>
    <div className={classes.planTop}>
      <Ribbon highlighted={highlighted} />
      <PlanName name={plan.details?.name || ''} />
      <Price price={plan.paymentOptions?.price} />
      <Recurrence paymentOptions={plan.paymentOptions} recurringPlansExist={recurringPlansExist} />
      <Tagline description={plan.details?.description || ''} />
      <Validity plan={plan} />
      <FreeTrial plan={plan} freeTrialDaysExist={freeTrialDaysExist} />
      <Cta plan={plan} highlighted={highlighted} onClick={onClick} />
    </div>
    <Footer benefits={plan.details?.benefits} anyBenefits={anyBenefits} isMobile={isMobile} highlighted={highlighted} />
  </div>
);
