import React from 'react';
import { Money } from '@wix/ambassador-pricing-plans-read-api/http';
import getSymbolFromCurrency from 'currency-symbol-map';

import { classes } from './Price.st.css';

interface Props {
  price?: Money;
}

export const Price: React.FC<Props> = ({ price }) => {
  return (
    <div>
      <span className={classes.currency}>{getSymbolFromCurrency(price?.currency)}</span>
      <span className={classes.price}>{price?.amount}</span>
    </div>
  );
};
