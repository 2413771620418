import React from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { classes } from './PlanName.st.css';

interface Props {
  name: string;
}

export const PlanName: React.FC<Props> = ({ name }) => {
  const settings = useSettings();
  const showPlanName = settings.get(settingsParams.showPlanName);

  if (!showPlanName) {
    return null;
  }

  return <h2 className={classes.planTitle}>{name}</h2>;
};
