import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { NO_BREAK_SPACE } from '../constants';

export const getFreeTrialDaysLabel = (plan: PublicPlan, t: any) => {
  if (!plan.paymentOptions?.freeTrialDays) {
    return NO_BREAK_SPACE;
  }

  if (plan.paymentOptions?.validFor?.forever) {
    return t('pp.free-trial-days-unlimited', { count: plan.paymentOptions.freeTrialDays });
  }

  return t('pp.free-trial-days', { count: plan.paymentOptions.freeTrialDays });
};
