import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useTranslation } from 'yoshi-flow-editor-runtime';

import { classes } from './Validity.st.css';
import { getPlanValidityCycle } from '../../../../utils';

interface Props {
  plan: PublicPlan;
}

export const Validity: React.FC<Props> = ({ plan }) => {
  const { t } = useTranslation();
  return <span className={classes.duration}>{getPlanValidityCycle(plan, t)}</span>;
};
